import styled from 'styled-components';
import SimpleBar from 'simplebar-react';

export const StyledSimpleBar = styled(SimpleBar)`
  & {
    .simplebar-scrollbar::before {
      background-color: white !important;
    }
  }
`;

export default StyledSimpleBar;
